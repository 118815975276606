import axios from 'axios';
import { action, computed, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import { SIGNUP_ALREADY_EXISTS } from 'constants/errors';
import { OFF, ON } from 'constants/inputField';
import { TYPE_ONE, TYPE_TWO } from 'constants/memberTypes';
import { AIS, PIS } from 'constants/permissions';
import isValidEmail from 'helpers/is-valid-email/isValidEmail';
import ModalStore from 'components/common/modals/ModalStore';
import { TOKEN } from 'constants/banks';
import { RESELLER } from 'constants/clientType';

import type AdminStore from 'components/admin/AdminStore';
import { I18nMapKeys, IFormSuperAdminModalStore } from '../types';
import React from 'react';
import type { AppStore } from 'AppStore';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class SuperAdminModalStore extends ModalStore {
    constructor(public rootStore: AdminStore) {
        super();
        this.rootStore = rootStore;
    }

    resellerMemberTypes = [TYPE_ONE, TYPE_TWO];
    products = [AIS, PIS];
    perPage = 10;
    page = 1;

    @observable
    loading = false;
    @observable
    form: IFormSuperAdminModalStore = {
        firstName: '',
        lastName: '',
        email: '',
        products: [],
        aliasValue: '',
        memberType: TYPE_ONE,
        selectAll: {
            products: false,
        },
        scope: TOKEN,
        resellerName: '',
    };
    @observable
    memberPermissions = [];
    @observable
    clientId = [];
    @observable
    memberType = '';

    validateForm = (form: IFormSuperAdminModalStore) => {
        const { firstName, lastName, email, aliasValue, products, resellerName } = form;
        const hasFirstName = firstName ? ON : OFF,
            hasLastName = lastName ? ON : OFF,
            hasEmail = email ? ON : OFF,
            hasAlias = aliasValue ? ON : OFF,
            hasProducts = products.length > 0 ? ON : OFF,
            hasResellerName = resellerName ? ON : OFF;
        return {
            hasFirstName,
            hasLastName,
            hasEmail,
            hasAlias,
            hasProducts,
            hasResellerName,
        };
    };

    @action
    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.form[event.target.name] = event.target.value;
    };

    @computed
    get isFilled() {
        const validateResult = this.validateForm(this.form);
        const {
            hasFirstName,
            hasLastName,
            hasEmail,
            hasAlias,
            hasProducts,
            hasResellerName,
        } = validateResult;

        return (
            hasFirstName === ON &&
            hasLastName === ON &&
            hasEmail === ON &&
            hasAlias === ON &&
            isValidEmail(this.form.email) &&
            hasProducts === ON &&
            hasResellerName === ON
        );
    }

    @action
    handleSave = flow(
        function* (this: SuperAdminModalStore, AppStore: AppStore) {
            this.loading = true;
            try {
                yield axios.post('/api/admin/super-admin', {
                    firstName: this.form.firstName,
                    lastName: this.form.lastName,
                    email: this.form.email,
                    permissions: this.form.products,
                    aliasValue: this.form.aliasValue,
                    memberType: this.form.memberType,
                    scope: this.form.scope,
                    resellerName: this.form.resellerName,
                });
                this.memberType = this.form.memberType;
                (this.rootStore.member as any).email = this.form.email;
                this.handleClear();
                this.closeModal();
                this.rootStore.openSuccessModal();
            } catch (e) {
                console.error(e);
                const errorCode =
                    (e as any)?.response?.data?.code === 'auth/email-already-in-use' &&
                    SIGNUP_ALREADY_EXISTS;
                AppStore.errorStore.openErrorModal(e, errorCode as I18nMapKeys);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    sentToEditPermissionsModal = async () => {
        this.rootStore.clientType = RESELLER;
        this.rootStore.UpdatePermissionsModalStore.clientType = RESELLER;
        this.rootStore.UpdatePermissionsModalStore.memberType = this.memberType;
        await this.rootStore.UpdatePermissionsModalStore.initStore(
            (this.rootStore.member as any).email,
        );
        this.rootStore.UpdatePermissionsModalStore.openModal();
    };

    @action
    handleSelectAll = (event: React.ChangeEvent<any>) => {
        const name = event.target.name;
        const checked = event.target.checked;
        this.form.selectAll[name as 'products'] = checked;
        if (!checked) {
            this.form[name] = [];
        } else {
            this.form[name] = this.products;
        }
    };

    @action
    handleClear = () => {
        this.form = {
            firstName: '',
            lastName: '',
            email: '',
            products: [],
            aliasValue: '',
            memberType: TYPE_ONE,
            selectAll: {
                products: false,
            },
            scope: TOKEN,
            resellerName: '',
        };
    };
}

export default SuperAdminModalStore;
