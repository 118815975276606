import Router from 'next/router';
import PropTypes from 'prop-types';
import { Component } from 'react';
import camelize from 'helpers/camelize/camelize';
import { getLinks } from './AdminLinks';
import { supportedMetricsCountries } from '../constants/countries';

export default class AdminBase extends Component {
    constructor(props) {
        super(props);

        this.setLinks();
        this.setGroups();

        if (!AdminBase.isServer) {
            // Scroll to the top of the page change
            Router.events.on('routeChangeComplete', () => {
                window.scrollTo(0, 0);
            });
        }
    }

    setLinks() {
        const { AppStore, route } = this.props;
        const parentLinks = getLinks(AdminBase.isServer ? route : Router.route);
        const nestedLinks = {};

        const subLinks = {};
        supportedMetricsCountries.map((key) => {
            subLinks[camelize(key.name)] = {
                link: `/metrics/${key.code}`,
            };
        });
        parentLinks['metrics'].subLinks = subLinks;

        AppStore.setLinks({
            parentLinks,
            nestedLinks,
        });
    }

    setGroups() {
        const { AppStore, groups } = this.props;
        AppStore.setOktaGroups(groups || []);
    }

    static get isServer() {
        return typeof window === 'undefined';
    }
}

AdminBase.propTypes = {
    AppStore: PropTypes.object.isRequired,
    route: PropTypes.string,
    groups: PropTypes.array,
};
