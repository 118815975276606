import axios from 'axios';
import { action, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import Router from 'next/router';
import ConfigurationStore from '../configuration/ConfigurationStore';
import ModalStore from '../common/modals/ModalStore';
import SuperAdminModalStore from '../common/modals/super-admin/SuperAdminModalStore';
import UpdatePermissionsModalStore from '../common/modals/update-permissions/UpdatePermissionsModalStore';
import CompanyInfoDetailsStore from '../account-settings/company-info/CompanyInfoDetailsStore';
import CreateTppStore from '../tpp-manager/create-tpp/CreateTppStore';
import MemberSearchStore from './member-search/MemberSearchStore';
import { TOKEN } from 'constants/banks';
import { RESELLER } from 'constants/clientType';
import _get from 'lodash/get';
import { isEmpty } from 'lodash';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

class AdminStore {
    MemberSearchStore = new MemberSearchStore(this);
    SuperAdminModalStore = new SuperAdminModalStore(this);
    GenericSuccessStore = new ModalStore();
    GenericErrorStore = new ModalStore();
    CompanyInfoDetailsStore = new CompanyInfoDetailsStore(this, false, true, false);
    CreateTppStore = new CreateTppStore(this, true);
    UpdatePermissionsModalStore = new UpdatePermissionsModalStore(this);
    ConfigurationStore = new ConfigurationStore(this, {
        isChildConfigurationView: true,
        isAdminDashboard: true,
    });
    clientId = '';
    resellerScope = TOKEN;

    @observable
    tppList = [];

    @observable
    page = {
        step: 1,
        type: 'ADMIN',
    };
    @observable
    resellerEmail = '';
    @observable
    resellerMemberId = '';
    @observable
    member = {};
    @observable
    user = {};
    @observable
    permissions = [];
    @observable
    clientType = RESELLER;
    @observable
    displayName = '';

    rowsPerPage = 10;
    originalFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    createdAtFormat = 'MMMM Do YYYY';

    @action
    sentToEditPermissionsModal = async () => {
        await this.SuperAdminModalStore.sentToEditPermissionsModal();
    };

    @action
    setResellerDetails = async () => {
        const url = new URLSearchParams();
        url.append('perPage', this.rowsPerPage);
        url.append('page', 1);
        url.append('memberId', decodeURIComponent(this.resellerMemberId));

        const res = await axios.get(`/api/admin/clients?${url.toString()}`);
        const clientId = res.data.clients[0].clientId;
        const response = await axios.get(`/api/admin/clients/${encodeURIComponent(clientId)}`);
        const member = response.data.memberDetails;
        this.resellerDetails = member;
    };

    @action
    setCurrentUserPermissions = async (permissions) => {
        this.permissions = permissions;
    };

    @action
    sendToAddConfigurations = () => {
        this.member.permissions = this.permissions;
        this.ConfigurationStore.initStore(
            this.clientType,
            this.member,
            _get(this.resellerDetails, 'memberId', ''),
            this.displayName,
            this.clientId,
        );
        this.ConfigurationStore.setTabs();
        Object.assign(this.page, {
            step: 1,
            type: 'CONFIGURATIONS',
        });
    };

    @action
    sendToTppDetails = async (tpp) => {
        if (!isEmpty(tpp)) {
            await Router.push(
                '/clients/[clientId]',
                `/clients/${encodeURIComponent(tpp.clientId)}`,
            );
        }
    };

    @action
    openCreateResellerModal = () => {
        this.SuperAdminModalStore.openModal();
    };

    @action
    openSuccessModal = () => {
        this.GenericSuccessStore.openModal();
    };

    @action
    navigateToCreateTppStep = () => {
        this.CompanyInfoDetailsStore.setDisableStep(true);
        this.page = {
            step: 1,
            type: 'CREATE',
        };
    };

    @action
    nextStep = () => {
        this.page.step++;
    };

    @action
    sendToTppManager = () => {
        this.page = {
            step: 1,
            type: 'ADMIN',
        };
    };

    @action
    getToRoot = () => {
        return Router.reload();
    };

    @action
    loadResellerDetails = flow(
        function* (memberId) {
            try {
                this.CompanyInfoDetailsStore.setDisableStep(true);
                this.CompanyInfoDetailsStore.setSnackbarState(true);
                this.CompanyInfoDetailsStore.setErrorState(false);
                this.resellerMemberId = memberId;
                const member = yield axios.get(
                    `api/admin/member?memberId=${this.resellerMemberId}`,
                );
                this.resellerEmail = member.data.email;
                delete member.data.email;
                this.member = member.data;
                this.resellerScope = member.data.scope;
                this.CompanyInfoDetailsStore.setSnackbarState(false);
                this.CompanyInfoDetailsStore.setDisableStep(false);
            } catch (e) {
                console.error(e);
                this.CompanyInfoDetailsStore.setErrorState(true);
            }
        }.bind(this),
    );
}

export default AdminStore;
