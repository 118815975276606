import React, { useEffect } from 'react';
import {
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';

import { cellConstructor } from 'components/common/wrappers/TableWrapper';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TableWrapper from 'components/common/wrappers/TableWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import Spinner from 'components/common/spinner/Spinner';
import CrossIcon from 'images/x-icon.svg';
import { CUSTOM_SEARCH_LABEL } from './MemberSearchStore';

import type { IMemberSearch } from './types';
import type { CellTemplates } from 'components/common/types';

const styles = () => ({
    root: {},
    icon: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&:focus': {
            boxShadow: 'none',
        },
    },
});

export const WAIT_TIME = 500;

const MemberSearch: React.FC<IMemberSearch> = ({ store, t, classes }) => {
    const {
        loading,
        form,
        resellerOptions,
        searchByOptions,
        TableWrapperStore,
        handleChange,
        handleInputClear,
        handleResellerChange,
        fetchResellersWithRealmId,
        getAllClients,
        handleSearch,
        handleRowClick,
    } = store;

    const debouncedSearch = React.useRef(
        debounce(() => {
            handleSearch();
        }, WAIT_TIME),
    ).current;

    const debouncedCustomSearch = React.useRef(
        debounce(() => {
            getAllClients(true);
        }, WAIT_TIME),
    ).current;

    useEffect(() => {
        if (form.reseller.name === CUSTOM_SEARCH_LABEL) {
            debouncedCustomSearch();
        } else {
            debouncedSearch();
        }
    }, [form.searchInput, form.searchBy]);

    useEffect(() => {
        let cancellablePromiseFetchAll: CancellablePromise;
        const cancellablePromiseFetchResellers = fetchResellersWithRealmId();
        cancellablePromiseFetchResellers.then(() => {
            cancellablePromiseFetchAll = getAllClients();
        });

        return () => {
            cancellablePromiseFetchResellers && cancellablePromiseFetchResellers.cancel();
            cancellablePromiseFetchAll && cancellablePromiseFetchAll.cancel();
        };
    }, []);

    const headers = [
        t('companyName'),
        t('memberId'),
        t('companyEmail'),
        t('alias'),
        t('clientStatus'),
        t('createdAt'),
    ];

    const cellTemplates: CellTemplates = [
        (row) => cellConstructor.text({ row, keyId: 'companyEmail', field: 'companyName' }),
        (row) => cellConstructor.text({ row, keyId: 'companyEmail', field: 'memberId' }),
        (row) => cellConstructor.text({ row, keyId: 'companyEmail', field: 'companyEmail' }),
        (row) => cellConstructor.text({ row, keyId: 'companyEmail', field: 'companyAlias' }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'companyEmail',
                field: 'status',
                value: t(row['clientStatus']),
                isBadge: true,
            }),
        (row) => cellConstructor.text({ row, keyId: 'companyEmail', field: 'createdAt' }),
    ];

    const isSearchByDisabled = [CUSTOM_SEARCH_LABEL, ''].includes(form.reseller.name);
    const isSearchInputDisabled = [''].includes(form.reseller.name);

    return (
        <div className="MemberSearch">
            <h4>{t('memberSearchSubheading')}</h4>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        id="reseller"
                        options={resellerOptions}
                        value={form.reseller}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={handleResellerChange}
                        renderInput={(params) => <TextField {...params} name={'reseller'} />}
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <SelectWrapper
                        name="searchBy"
                        value={form.searchBy}
                        disabled={isSearchByDisabled}
                        onChange={handleChange}
                        required>
                        {searchByOptions.map((s) => (
                            <MenuItem key={s} value={s}>
                                {t(s)}
                            </MenuItem>
                        ))}
                    </SelectWrapper>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextFieldWrapper
                        placeholder={`Search with ${t(form.searchBy)}`}
                        value={form.searchInput}
                        name={'searchInput'}
                        className="searchInput"
                        hideOptional
                        disabled={isSearchInputDisabled}
                        onChange={(e) => handleChange(e)}
                        InputProps={{
                            readOnly: false,
                            endAdornment: (
                                <InputAdornment position="end">
                                    {loading ? (
                                        <Spinner
                                            type={Spinner.TYPE_SMALL}
                                            color={Spinner.COLOR_SECONDARY}
                                        />
                                    ) : (
                                        form.searchInput && (
                                            <IconButton
                                                className={classes.icon}
                                                aria-label="clear search input"
                                                onClick={handleInputClear}>
                                                <img src={CrossIcon} />
                                            </IconButton>
                                        )
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        autoFocus
                    />
                </Grid>
            </Grid>
            <TableWrapper
                header={headers}
                disableRowSelect={false}
                callback={handleRowClick}
                cellTemplates={cellTemplates}
                noDataMessage={t('noTPPs')}
                loading={loading}
                TableWrapperStore={TableWrapperStore}
                serverSidePagination={false}
                enablePagination
            />
        </div>
    );
};

export default withStyles(styles, { name: 'PasswordField' })(observer(MemberSearch));
