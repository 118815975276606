import type { MuiStyles, Translation } from 'types';
import type MemberSearchStore from './MemberSearchStore';

import clientTypes from 'constants/clientType';

export interface IMemberSearch extends MuiStyles {
    store: MemberSearchStore;
    t: Translation;
}

export interface IresellerOption {
    name: string;
    memberId: string;
}

export interface IProdReseller {
    HSBC: string;
    TokenGMBH: string;
    BNPParibas: string;
    BNPFortis: string;
}

export interface IMemberSearchForm {
    reseller: IresellerOption;
    searchBy: SEARCH_BY;
    searchInput: string;
    [k: string]: any;
}

export enum SEARCH_BY {
    name = 'companyName',
    alias = 'companyAlias',
    email = 'users',
    memberId = 'memberId',
}

export interface ImemberSearchResult {
    clientEmail: string;
    clientId: string;
    companyName: string;
    clientStatus: string;
    createdAt: string;
    memberId: string;
    companyAlias: string;
    clientType: keyof typeof clientTypes;
    isDraft?: boolean;
    users: string[];
}
