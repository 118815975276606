import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AppController from '../../base/App.controller';
import DefaultContainer from '../../layout/DefaultContainer';
import GenericSuccessModal from '../common/modals/success/GenericSuccess';
import SuperAdminModal from '../common/modals/super-admin/SuperAdminModal';
import CustomThemeProvider from '../common/theme/CustomThemeProvider';
import ButtonWrapper from '../common/wrappers/ButtonWrapper';
import CustomDivider from '../common/wrappers/CustomDivider';
import successIcon from '../../images/password-reset.svg';
import GenericErrorModal from '../common/modals/error/GenericError';
import UpdatePermissionsModal from '../common/modals/update-permissions/UpdatePermissionsModal';
import MemberSearch from 'components/admin/member-search/MemberSearch';

function Admin({ AppStore, AdminStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'admin');
    const {
        openCreateResellerModal,
        SuperAdminModalStore,
        GenericSuccessStore,
        GenericErrorStore,
        navigateToCreateTppStep,
        sentToEditPermissionsModal,
        MemberSearchStore,
    } = AdminStore;

    return (
        <div className={'Admin'}>
            <DefaultContainer>
                <Grid container justify="space-between">
                    <Grid item md={4} lg={6}>
                        <h1>{t('title')}</h1>
                    </Grid>
                    <Grid
                        container
                        justify="flex-end"
                        spacing={2}
                        item
                        md={8}
                        lg={5}
                        className={'buttonContainer'}>
                        <Grid item xs={4}>
                            <ButtonWrapper onClick={navigateToCreateTppStep} className="createTpp">
                                {t('createTpp')}
                            </ButtonWrapper>
                        </Grid>
                        <Grid item xs={5}>
                            <ButtonWrapper
                                onClick={openCreateResellerModal}
                                className="createReseller">
                                {t('createReseller')}
                            </ButtonWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <CustomDivider />
                <div className="fieldContainer">
                    <MemberSearch store={MemberSearchStore} t={t} />
                </div>
            </DefaultContainer>
            <CustomThemeProvider alt>
                <SuperAdminModal SuperAdminModalStore={SuperAdminModalStore} />
                <GenericSuccessModal
                    Store={GenericSuccessStore}
                    title={t('modalTitle')}
                    subtitle={t('modalDescription')}
                    buttonText={t('common:next')}
                    icon={successIcon}
                    onClick={sentToEditPermissionsModal}
                />
                <GenericErrorModal Store={GenericErrorStore} />
                <UpdatePermissionsModal
                    UpdatePermissionsModalStore={AdminStore.UpdatePermissionsModalStore}
                />
            </CustomThemeProvider>
        </div>
    );
}

Admin.propTypes = {
    AppStore: PropTypes.object.isRequired,
    AdminStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'AdminStore')(observer(Admin));
