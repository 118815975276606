import axios from 'axios';
import Fuse from 'fuse.js';
import { action, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';

import ModalStore from 'components/common/modals/ModalStore';
import _TableWrapperStore from 'components/common/wrappers/TableWrapperStore';

import { RESELLER } from 'constants/clientType';

import { IMemberSearchForm, ImemberSearchResult, IresellerOption, SEARCH_BY } from './types';
import type AdminStore from '../AdminStore';
import type { AsyncGeneratorReturnType } from 'types';
import type { IRow } from 'components/common/types';
import moment from 'moment';

const { publicRuntimeConfig } = require('next/config').default();

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);

const SEARCH_BY_OPTIONS = Object.values(SEARCH_BY);
const MEMBER_ID_MIN_LENGTH = 38;

export const CUSTOM_SEARCH_LABEL = 'Other';

class MemberSearchStore {
    constructor(public rootStore: AdminStore) {}

    TableWrapperStore = new _TableWrapperStore();
    GenericSuccessStore = new ModalStore();
    GenericErrorStore = new ModalStore();
    allData = [];
    rowsPerPage = 10;
    originalFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    createdAtFormat = 'MMMM Do YYYY';

    @observable
    loading = false;

    @observable
    form: IMemberSearchForm = {
        reseller: {
            name: '',
            memberId: '',
        },
        searchBy: SEARCH_BY.name,
        searchInput: '',
    };

    @observable
    resellerOptions: IresellerOption[] = [];

    @observable
    searchByOptions = SEARCH_BY_OPTIONS;

    @action
    handleResellerChange = (event: React.ChangeEvent<{}>, value: IresellerOption | null) => {
        event.preventDefault();
        this.form['reseller'] = value || {
            name: '',
            memberId: '',
        };
        if (value) {
            if (value.name === CUSTOM_SEARCH_LABEL) {
                if (this.form.searchBy === SEARCH_BY.memberId) {
                    this.getAllClients(true);
                } else {
                    this.form.searchBy = SEARCH_BY.memberId;
                }
            } else {
                this.getAllClients();
            }
        }
    };

    @action
    fetchResellersWithRealmId = flow(
        function* (): AsyncGeneratorReturnType<{ resellers: IresellerOption[] }> {
            this.loading = true;
            try {
                const res = yield axios.get('/api/admin/resellers');
                const resellers = res?.data?.resellers || [];

                this.resellerOptions = resellers.concat({
                    name: CUSTOM_SEARCH_LABEL,
                    memberId: '',
                });
                this.setDefaultReseller(this.resellerOptions);
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    setDefaultReseller = (resellerOptions: IresellerOption[]) => {
        const option = resellerOptions.find(
            (option) => option.memberId === publicRuntimeConfig.memberSearchDefaultResellerRealId,
        );
        this.form.reseller = option ?? resellerOptions[0];
    };

    @action
    handleSearch = (isCustomSearch: boolean = false) => {
        const { searchInput, searchBy } = this.form;
        let data: ImemberSearchResult[] = [];
        if (searchInput) {
            data = this.getPartialSearchResult(this.allData, searchBy, searchInput);
        } else {
            data = isCustomSearch ? [] : this.allData;
        }
        this.TableWrapperStore.setData(this.highlightReseller(data));
    };

    @action
    handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        this.form[name] = value;
    };

    @action
    getAllClients = flow(
        function* (
            isCustomSearch: boolean = false,
        ): AsyncGeneratorReturnType<{ clients: ImemberSearchResult[] }> {
            this.loading = true;
            try {
                let memberSearchResult: ImemberSearchResult[];
                // make call only when searching by mID
                if (isCustomSearch && !(this.form.searchInput.length >= MEMBER_ID_MIN_LENGTH)) {
                    memberSearchResult = [];
                } else {
                    const res = yield axios.get(
                        `/api/admin/member-search?${this.constructUrl(isCustomSearch)}`,
                    );
                    memberSearchResult = res?.data?.clients || [];
                }
                this.allData = this._formatCreatedDate(memberSearchResult);
                if (this.allData.length) {
                    this.allData = [this.allData.shift()].concat(
                        this.sortByCompanyName(this.allData),
                    );
                }
                this.TableWrapperStore.setMaxRows(this.rowsPerPage);
                this.handleSearch(isCustomSearch);
            } catch (e) {
                console.error(e);
                this.rootStore.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );

    @action
    handleInputClear = () => {
        this.form.searchInput = '';
    };

    _formatCreatedDate = (clients: ImemberSearchResult[]) => {
        return clients.map((client) => {
            if (client.createdAt) {
                const formattedDate = moment(client.createdAt).format(
                    'ddd, DD MMM YYYY HH:mm:ss [GMT]',
                );
                client.createdAt = formattedDate;
            }

            return client;
        });
    };

    getPartialSearchResult = (
        data: ImemberSearchResult[],
        searchBy: SEARCH_BY,
        searchTerm: string,
    ) => {
        const fuseOptions = {
            keys: [searchBy],
            threshold: 0.3,
        };
        const fuse = new Fuse(data, fuseOptions);

        return fuse.search(searchTerm).map((result) => result.item);
    };

    highlightReseller = (data: ImemberSearchResult[]) => {
        const reseller = data?.find((client) => client.clientType === RESELLER);
        if (reseller) {
            reseller.isDraft = true;
        }

        return data;
    };

    handleRowClick = (client: IRow) => {
        this.rootStore.sendToTppDetails(client);
    };

    constructUrl = (isCustomSearch: boolean) => {
        const url = new URLSearchParams({
            realmId: isCustomSearch ? '' : this.form.reseller.memberId,
            tppMemberId: isCustomSearch ? this.form.searchInput : '',
        });

        return url;
    };

    sortByCompanyName = (data: ImemberSearchResult[]) => {
        const result = data.sort((a, b) => {
            const [name1, name2] = [a.companyName, b.companyName];
            return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
        });
        return result;
    };
}

export default MemberSearchStore;
