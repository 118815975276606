import { inject, observer, Provider } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import AdminBase from '../../base/AdminBase';
import AppController from '../../base/App.controller';
import AdminStore from '../../components/admin/AdminStore';
import AdminLayout from '../../layout/AdminLayout';
import AdminController from '../../components/admin/Admin.controller';

class AdminPage extends AdminBase {
    state = {
        Store: new AdminStore(),
    };

    static async getInitialProps({ req, res }) {
        const controller = new AppController(req, res);

        const { groups } = await controller.validateOktaSession();

        return {
            groups,
            route: req && req.url,
            namespacesRequired: controller.getNamespaces(['app', 'admin']),
        };
    }

    render() {
        const { page } = this.state.Store;

        return (
            <Provider
                AdminStore={this.state.Store}
                TppManagerStore={this.state.Store}
                ConfigurationStore={this.state.Store.ConfigurationStore}>
                <AdminLayout>{AdminController.getComponent(page)}</AdminLayout>
            </Provider>
        );
    }
}

AdminPage.propTypes = {
    AppStore: PropTypes.object.isRequired,
    groups: PropTypes.array,
};

export default inject('AppStore')(observer(AdminPage));
