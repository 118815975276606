import React from 'react';
import Configuration from '../configuration/Configuration';
import CreateTppStep1 from '../tpp-manager/create-tpp/CreateTppStep1';
import CreateTppStep2 from '../tpp-manager/create-tpp/CreateTppStep2';
import Admin from './Admin';

const COMPONENTS = {
    ADMIN_1: <Admin />,
    CREATE_1: <CreateTppStep1 />,
    CREATE_2: <CreateTppStep2 />,
    CONFIGURATIONS_1: <Configuration />,
};

export default class AdminController {
    static getComponent(page) {
        return COMPONENTS[`${page.type}_${page.step}`];
    }
}
