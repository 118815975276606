import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { inject, observer } from 'mobx-react';
import Head from 'next/head';
import React, { useState } from 'react';
import AppController from '../base/App.controller';
import DrawerWrapper from '../components/common/drawer/Drawer';
import GenericErrorModal from '../components/common/modals/error/GenericError';
import ToolbarWrapper from '../components/common/toolbar/Toolbar';
import { UseTranslation } from 'next-i18next';
import { IAdminLayout } from './types';

const StyledCloseIcon = withStyles({
    root: {
        width: '20px',
        height: '20px',
        marginTop: '120px',
        marginLeft: '230px',
        borderRadius: 15,
        backgroundColor: '#ffffff',
        position: 'fixed',
        zIndex: 1000,
        border: '1px solid #DCDCDC',
    },
})(IconButton);

const StyledOpenIcon = withStyles({
    root: {
        width: '20px',
        height: '20px',
        marginTop: '120px',
        marginLeft: '-20px',
        borderRadius: 15,
        backgroundColor: '#ffffff',
        position: 'absolute',
        zIndex: 950,
        border: '1px solid #DCDCDC',
    },
})(IconButton);

const AdminLayout: React.FC<IAdminLayout> = ({ AppStore, children }) => {
    const { t }: { t: UseTranslation } = AppController.getTranslation(AppStore.scope, 'app');
    const { errorStore } = AppStore;
    const [showDrawer, setShowDrawer] = useState(true);
    const _handleDrawerOpen = () => {
        if (!showDrawer) {
            setShowDrawer(true);
        } else {
            setShowDrawer(false);
        }
    };

    return (
        <div className="baseLayout">
            <Head>
                <title>{String(t('appTitle'))}</title>
            </Head>
            <div className="baseContainer">
                {/* made AppStore Optional  */}
                <ToolbarWrapper hideMenu />
                {showDrawer ? (
                    <>
                        <StyledCloseIcon onClick={_handleDrawerOpen}>
                            <NavigateBefore htmlColor={'#DCDCDC'} />
                        </StyledCloseIcon>
                        {/* made AppStore Optional  */}
                        <DrawerWrapper disabled={false} permissions={[]} />
                    </>
                ) : (
                    <>
                        <StyledOpenIcon onClick={_handleDrawerOpen}>
                            <NavigateNext htmlColor={'#DCDCDC'} />
                        </StyledOpenIcon>
                    </>
                )}
                <main className="content">
                    <div className="toolbarOffset" />
                    {children}
                </main>
                <GenericErrorModal Store={errorStore} />
            </div>
        </div>
    );
};

export default inject('AppStore')(observer(AdminLayout));
