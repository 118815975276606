import { Grid, Paper, Tab, Tabs } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../base/App.controller';
import successIcon from '../../images/password-reset.svg';

import DefaultContainer from 'layout/DefaultContainer';
import Breadcrumbs from 'components/common/breadcrumbs/Breadcrumbs';
import GenericErrorModal from 'components/common/modals/error/GenericError';
import GenericSuccessModal from 'components/common/modals/success/GenericSuccess';
import Modal from 'components/common/modals/modal';
import Spinner from 'components/Spinner';
import ButtonWrapper from 'components/common/wrappers/ButtonWrapper';

// helpers
import conditional from 'helpers/conditional/conditional';
import ConfigurationController from './Configuration.controller';

function Configuration({ AppStore, ConfigurationStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'configuration');
    const { handleFetchFeatures, rootLoading } = ConfigurationStore.FeatureStore;
    const {
        allTabs,
        tabValue,
        handleTabChange,
        GenericErrorStore,
        handleUnsavedConfirm,
        isChildConfigurationView,
        isAdminDashboard,
        displayName,
        rootStore,
        hideTabs,
        isSubTpp,
        tppName,
    } = ConfigurationStore;

    const ActiveTab = allTabs[tabValue];

    const _previousStep = () => {
        if (isSubTpp && !isAdminDashboard) {
            return Router.back();
        }
        rootStore.getToRoot();
    };

    useEffect(() => {
        hideTabs(AppStore);
        handleFetchFeatures();
    }, []);

    return (
        <div className="ConfigurationMain">
            <Grid container spacing={2} justify="space-between">
                {isChildConfigurationView ? (
                    <Breadcrumbs
                        previousStep={_previousStep}
                        parentPath={conditional(
                            rootStore.isTpp,
                            t('underReseller'),
                            conditional(isSubTpp, t('subTppParentTitle'), t('parentTitle')),
                        )}
                        currentPath={conditional(
                            displayName,
                            `${t('addConfigurations')} for ${displayName}`,
                            conditional(tppName, tppName, t('addConfigurations')),
                        )}
                    />
                ) : (
                    <Grid item xs={4}>
                        <h1>{t('title')}</h1>
                    </Grid>
                )}
            </Grid>

            {rootLoading ? (
                <Spinner />
            ) : tabValue ? (
                <>
                    <DefaultContainer>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            className={'configTabs'}
                            variant="scrollable">
                            {Object.keys(allTabs).map((key) => {
                                const tabkey = ConfigurationController.renameTabsForWebappV2(key);
                                return <Tab key={key} label={t(tabkey)} value={key} />;
                            })}
                        </Tabs>
                    </DefaultContainer>
                    <ActiveTab />
                </>
            ) : (
                <Paper className={'noData'}>
                    <div>{t('noPermissions')}</div>
                </Paper>
            )}

            <GenericSuccessModal
                Store={ConfigurationStore.GenericSuccessStore}
                title={t('modalTitle')}
                subtitle={t('modalDescription')}
                buttonText={t('modalButton')}
                icon={successIcon}
            />

            <Modal
                Store={ConfigurationStore.UnsavedChangesModalStore}
                title={t('modalUnsavedChangesTitle')}
                description={t('modalUnsavedChangesDescription')}
                buttons={
                    <>
                        <ButtonWrapper
                            color="secondary"
                            className="closeButton unsaved-changes-close"
                            onClick={() => {
                                ConfigurationStore.UnsavedChangesModalStore.closeModal();
                            }}>
                            {t('modalCancel')}
                        </ButtonWrapper>
                        <ButtonWrapper
                            className="confirmButton  unsaved-changes-confirm"
                            onClick={handleUnsavedConfirm}>
                            {t('modalConfirm')}
                        </ButtonWrapper>
                    </>
                }
            />
            <GenericErrorModal Store={GenericErrorStore} />
        </div>
    );
}

Configuration.propTypes = {
    AppStore: PropTypes.object.isRequired,
    ConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'ConfigurationStore')(observer(Configuration));
