import makeStyles from '@material-ui/styles/makeStyles';
import { Paper } from '@material-ui/core';
import Spinner from 'components/common/spinner/Spinner';

interface Props {
    withPaper?: boolean;
}

export default function _Spinner({ withPaper }: Props) {
    const classes = useStyles();
    return withPaper ? (
        <Paper className={classes.root}>
            <Spinner type={Spinner.TYPE_LARGE} color={Spinner.COLOR_SECONDARY} />
        </Paper>
    ) : (
        <div className={classes.root}>
            <Spinner type={Spinner.TYPE_LARGE} color={Spinner.COLOR_SECONDARY} />
        </div>
    );
}

const useStyles = makeStyles((theme: any) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'center',
            padding: theme.spacing(2),
        },
    };
});
